$(document).ready(function () {

    if ($('#memberships').length) {
        setTimeout(function () {
            checkAmount();
            if ($('.discount_choice').hasClass('yadiscount')) {
                $('.discount_choice .toggle').trigger('click')
            }
        }, 500)

        if ($('.row_delete').length <= 1) {
            $('.btn-delete').hide();
        }


        // si discount on affiche les details
        $(document).on({
            click: function () {
                if ($('.discount_choice .toggle ').hasClass('off')) {
                    $('.discount_block').hide('slow');
                    $('.discount_block input').each(function () {
                        $(this).val(0);
                    })
                    checkAmount();
                } else {
                    $('.discount_block').show('slow');
                }
            }
        }, '.discount_choice');


        $(document).on({
            keyup: function () {
                checkAmount($(this))
            }
        }, '.amount_registered, #membership_edit_discounts_0_amount');

        $(document).on({
            change: function () {
                checkAmount($(this))
            }
        }, '.amount_registered, #membership_edit_discounts_0_amount');

        $(document).on({
            click: function () {
                checkAmount($(this))
            }
        }, '.reduction_type .toggle');

        $(document).on({
            click: function () {
                setTimeout(function () {
                    checkAmount($(this))
                }, 500)
            }
        }, '.btn-delete');

        $(document).on({
            change: function () {
                $('.amount_registered').trigger('change');
            }
        }, '.subs_details');


        function checkAmount(target) {
            let selectedAmount = $('.selected_service option:selected').attr('data-amount'),
                discountAmount = $('#membership_edit_discounts_0_amount').val(),
                initialAmountRaw = 0,
                amountInputs = $('.amount_registered'),
                amounts = [],
                totalAmounts = 0,
                message = '',
                amountLeftMessage = $('.amount_left_message');


            if ($('.reduction_type .toggle ').hasClass('off')) {
                initialAmountRaw += selectedAmount - ((selectedAmount * discountAmount) / 100)
            } else {
                initialAmountRaw += selectedAmount - discountAmount
            }
            $(amountInputs).each(function () {
                let amount = $(this).val();
                amounts.push(Number(amount))
            })
            for (var i = 0; i < amounts.length; i++) {
                totalAmounts += amounts[i];
            }

            let initialAmount = initialAmountRaw.toFixed(2);
            let amountLeftRaw = Number(initialAmount) - Number(totalAmounts);
            let amountLeft = amountLeftRaw.toFixed(2);
            let zero = 0;
            if (initialAmount !== 0) {
                $('.montant_final').text(initialAmount + ' €')
            }
            if (amountLeft !== zero.toFixed(2)) {
                message += 'Il reste ' + amountLeft + '€ à poster'
                $(amountLeftMessage).addClass("invalid")
                if ($('.services').length == 0) {
                    $('.creation_form button[type=submit]').prop('disabled', true);
                }
                $(target).closest('.row_delete').find('.amount_registered').parent().addClass('amount_wrong')
                $(target).closest('.row_delete').find('.amount_registered').parent().removeClass('amount_ok')
                $('.add-another-collection-widget').prop('disabled', false);
            } else {
                message += 'Vous pouvez valider les paiements'
                $(amountLeftMessage).removeClass('invalid')

                if ($('.services').length == 0) {
                    $('.creation_form button[type=submit]').prop('disabled', false);
                }
                $(target).closest('.row_delete').find('.amount_registered').parent().addClass('amount_ok')
                $(target).closest('.row_delete').find('.amount_registered').parent().removeClass('amount_wrong')
                $('.add-another-collection-widget').prop('disabled', true);
            }
            $(amountLeftMessage).text(message)
        }


        $(document).on({
            click: function () {
                $('#loader').show();
                let cotisationId = $(this).attr("data-cotisationid");
                $.ajax({
                    type: "POST",
                    url: router.routing.generate('ajax-get-membership-paiement-details'),
                    data: 'cotisationId=' + cotisationId,
                    dataType: 'json',
                    success: function (msg) {
                        $('#loader').hide();
                        $('#ajax_render_details').show();
                        document.getElementById('ajax_render_details').innerHTML = msg.returnHtml;
                    }
                });
            }
        }, '.see_membership_details');

        $(document).on({
            click: function () {
                $('#ajax_render_details').hide();
            }
        }, '#ajax_render_details .closed');
    }
});
